import { Spinner } from '@zapier/design-system';
import styled from 'styled-components';

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 3rem;
`;

const Loading = () => (
  <Centered>
    <Spinner />
  </Centered>
);

export default Loading;
