import { ConfigProvider } from 'antd';
import { Provider } from 'jotai';
import type { AppProps } from 'next/app';

import NoSSR from '@/components/NoSSR';
import SuspenseLoading from '@/components/SuspenseLoading';
import '@/styles/globals.css';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <SuspenseLoading>
      <Provider>
        <NoSSR>
          <ConfigProvider
            theme={{
              token: {
                controlOutlineWidth: 1,
                controlOutline: `#3d4592`,
                colorPrimaryHover: `#3d4592`,
                controlItemBgHover: `#f0f1fa`,
                fontFamily: `var(--zds-typography-base, "Inter", Helvetica, arial, sans-serif);`,
                borderRadius: 3,
              },
            }}
          >
            <Component {...pageProps} />
          </ConfigProvider>
        </NoSSR>
      </Provider>
    </SuspenseLoading>
  );
}
